import 'slick-carousel';

import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initA11yFunctions from '/lib/accessibility'
import initAjaxFuncs from '/lib/ajax'

initGreenSock()
initHeadroom()
initMobileNav()
initA11yFunctions()
initAjaxFuncs()
